
import { useFetchApi } from '../utils/UseFetchApi'
import React from 'react';

export interface IJobExamples {
    label: string,
    job: string,
}

export interface IDataCenterJobExamples {
    dataCenter: string,
    jobs: IJobExamples[],
}

export function useJobExamples() {

    const jobExamples = useFetchApi<IDataCenterJobExamples[]>(window.location.origin + '/api/v1/jobs/examples');

    React.useEffect(() => {

        function initialFetch() {
            jobExamples.run();
        }

        initialFetch();
    }, []);

    return jobExamples;
}
