import React from 'react'
import { AuthenticationProvider, oidcLog, OidcSecure } from '@axa-fr/react-oidc-context';
import { useConfig } from './ConfigLoader';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Typography } from '@mui/material';

const serverUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');


function BentleyEmployeeOnly(props: any) {

    const { oidcUser } = useReactOidc();

    if (!oidcUser.profile.email.toUpperCase().endsWith("@BENTLEY.COM"))
        return <Typography variant="h4">Access denied</Typography>

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export function Authenticate(props: any) {

    const config = useConfig();

    const oidcConfiguration = {
        client_id: 'reality-conversion-webapp',
        redirect_uri: serverUrl + '/signin-oidc',
        response_type: 'code',
        scope: 'openid reality-conversion-service-3372 email profile organization',
        authority: config.ims.authorityUri,
        silent_redirect_uri: serverUrl + '/silent_renew',
        automaticSilentRenew: true,
        loadUserInfo: true,
        triggerAuthFlow: true,
        silentRequestTimeout: 20000,
        accessTokenExpiringNotificationTime: 1800
    };

    return (
        // DOC: 
        // https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme
        <AuthenticationProvider
            configuration={oidcConfiguration}
            loggerLevel={oidcLog.WARN}
            authenticating={React.Fragment}
            callbackComponentOverride={React.Fragment}
        >
            <OidcSecure>
                <BentleyEmployeeOnly>
                    {props.children}
                </BentleyEmployeeOnly>
            </OidcSecure>
        </AuthenticationProvider>
    )
}
