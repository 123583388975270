import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Green from '@mui/material/colors/green';
import { Box } from '@mui/material';
import { useReactOidc } from '@axa-fr/react-oidc-context';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        title: {
            flexGrow: 1,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(1),
        },

        avatar: {
            backgroundColor: Green[400],
        }
    }),
);

export default function ProfileAvatar() {

    const classes = useStyles();
    const { oidcUser, logout } = useReactOidc();
    const { profile } = oidcUser;

    const [anchorEl, SetAnchorEl] = React.useState<any>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        SetAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        SetAnchorEl(null);
    };

    const initials = () => {
        return profile.given_name[0] + profile.family_name[0];
    }

    const open = Boolean(anchorEl);

    return (
        <div className={classes.root} style={{ width: 200 }}>
            <Grid container justifyContent="center" alignItems="center">

                <IconButton aria-owns={open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <Typography
                        variant="subtitle1"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        <Box component="div" display={{ xs: 'none', sm: 'block' }}>{profile.given_name} {profile.family_name}</Box>
                    </Typography>
                    <Avatar className={classes.avatar}>{initials()}</Avatar>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem selected={true}>
                        <Typography
                            variant="subtitle1"
                            color="inherit"
                            noWrap
                            className={classes.title}>
                            {profile.name}
                        </Typography>
                    </MenuItem>
                    <MenuItem selected={true}>
                        <Typography
                            variant="subtitle2"
                            color="inherit"
                            noWrap
                            className={classes.title}>
                            {profile.email}
                        </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={logout}>Sign Out</MenuItem>
                </Menu>
            </Grid>
        </div>
    );
}
