export const ApplicationName = "Reality Conversion Service";

// Time spans in milliseconds
export const OneSecInMilliseconds = 1000;
export const OneMinInMilliseconds = 60 * 1000;

// Intervals
export const ActiveJobsRefreshInterval = 20 * OneSecInMilliseconds;
export const JobProgressRefreshInterval = 30 * OneSecInMilliseconds;
export const JobStepsRefreshInterval = 30 * OneSecInMilliseconds;
