import * as React from 'react';
import { DLQMessageCleanUp } from 'realityservices-ui-components';

export default function ClearDLQMessages() {
    const queues: any[] = ["jobMessage", "nodeMessage"];

    function getDeleteDlqMsgUrl(rowData: any) {
        return window.location.origin + "/api/v1/servicebus/" + rowData.name + "/messageCount/" + rowData.dlqMessgeCount;
    }


    return (
        <div>
            <DLQMessageCleanUp queues={queues} title={"Queues"} controllerBaseUrl={window.location.origin + "/api/v1/servicebus/"} getDeleteDlqMsgUrl={getDeleteDlqMsgUrl} />
        </div>
    );
}

