import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFetchApi } from './UseFetchApi';
import useInterval from './UseInterval';
import { JobProgressRefreshInterval } from './Constants';

export interface IJobProgress {
    id: string;
    userId: string;
    percentage: number;
    state: string;
    _etag: string;
}

export interface IJobProgressResponse {
    progress: IJobProgress;
}

// Copied from official demo doc: https://material-ui.com/components/progress/#circular-with-label
function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function Progress(props: { jobId: string, autoRefresh: boolean }) {
    // one fetchApi per Progress component,
    // to avoid conflicts during simultaneous fetches
    const progressFetch = useFetchApi<IJobProgressResponse>(window.location.origin
        + '/api/v1/jobs/' + props.jobId + '/progressadmin');

    // componentDidMount
    React.useEffect(() => {
        progressFetch.run();
    }, []);

    useInterval(() => {
        progressFetch.run();
    }, props.autoRefresh ? JobProgressRefreshInterval : null);

    // render
    return (
        !progressFetch.data || progressFetch.error
            ? <CircularProgress disableShrink={true} size={30} />
            : <CircularProgressWithLabel value={progressFetch.data.progress.percentage} variant="determinate" />
    );
}


