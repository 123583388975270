import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
    return (
        createStyles({
            progress: {
                margin: theme.spacing(2),

            },
            centeredProgress: {
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
            },
        }));
    }
);

export default function CenteredProgress() {
    const classes = useStyles();
    return (
        <div className={classes.centeredProgress}>
            <CircularProgress className={classes.progress} />
        </div>
    );
}
