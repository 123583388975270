import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { Button, Menu, MenuItem } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    }),
);

export default function MenuButton(props: { index: number, choices: string[], onChanged: any }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(props.index);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        props.onChanged(index);
    };

    return (
        <React.Fragment>
            <Button className={classes.button} variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {props.choices[selectedIndex]}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.choices.map((choice, index) => (
                    <MenuItem key={index} onClick={event => handleMenuItemClick(event, index)}>{choice}</MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}
