import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/styles' {
    interface DefaultTheme extends Theme { }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#9BA5AE'
        },
        secondary: {
            main: '#008BE1'
        }
    }
});

export default theme;
