import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import MaterialTable from "@material-table/core";
import ReactJson from 'react-json-view';
import { IJobDetailed, JobLink, useJobActions, useJobReport } from '../Model/Job';
import { createDateColumn } from '../utils/DateUtils';
import useInterval from '../utils/UseInterval';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Progress from '../utils/ProgressUtils';
import { ActiveJobsRefreshInterval } from '../utils/Constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        }
    }),
);

export default function ActiveJobs() {
    const classes = useStyles();
    const jobReport = useJobReport('state=Active', true);
    const jobActions = useJobActions();
    const [autoRefresh, setAutoRefresh] = React.useState(true);

    function getPageSize(): number {
        return jobReport.data && (jobReport.data.length > 5) ? 10 : 5;
    }

    function toggleAutoRefresh() {
        if (autoRefresh) {
            setAutoRefresh(false);
        }
        else {
            setAutoRefresh(true);
            jobReport.refresh();
        }
    }

    useInterval(() => {
        if (jobReport.error) {
            setAutoRefresh(false);
            return;
        }

        jobReport.refresh();
    }, autoRefresh ? ActiveJobsRefreshInterval : null);

    return (
        <div className={classes.root} >
            <MaterialTable<IJobDetailed>
                key={getPageSize()} // HACK: To refresh when pagesize changes : https://stackoverflow.com/questions/38892672/react-why-child-component-doesnt-update-when-prop-changes
                title="Active Jobs"
                isLoading={jobReport.fetchCount === 0} // Avoid flicker on auto refresh and show loading only on first attempt
                columns={[
                    { title: "Progress", field: "id", render: rowData => (<Progress jobId={rowData.id} autoRefresh={autoRefresh} />), sorting: false, filtering: false, editable: "never", width: 'auto', align: 'center'},
                    { title: "Name", field: "name", sorting: true, render: rowData => <JobLink job={rowData} />  },
                    { title: "Type", field: "type", sorting: true },
                    { title: "Email", field: "email", sorting: true },
                    createDateColumn("Submission", "executionInformation.submissionDateTime", "desc"),
                    createDateColumn("Start", "executionInformation.startedDateTime"),
                    { title: "location", field: "dataCenter.location", sorting: true },
                ]}
                data={jobReport.data ?? []}
                detailPanel={rowData => {
                    return (
                        <ReactJson src={rowData} displayDataTypes={false} displayObjectSize={false} />
                    )
                }}
                actions={[
                    {
                        icon: () => autoRefresh ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />,
                        tooltip: 'Auto Refresh',
                        isFreeAction: true,
                        onClick: () => toggleAutoRefresh()
                    },
                    rowData => ({
                        tooltip: 'Download logs',
                        icon: 'get_app',
                        onClick: (_, rowData) => jobActions.downloadLogs(rowData)
                    }),
                    rowData => ({
                        tooltip: 'Terminate Job',
                        icon: 'cancel',
                        onClick: (_, rowData) => jobActions.terminate(rowData)
                    })
                ]}
                options={{
                    filtering: false,
                    pageSize: getPageSize(),
                    search: true,
                    sorting: true,
                    headerStyle: { backgroundColor: jobReport.error ? '#FF0000' : '#9BA5AE', color: '#000000' },
                    padding: 'dense'
                }}
            />
        </div>
    );

}


