import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import MaterialTable from "@material-table/core";
import ReactJson from 'react-json-view'
import HoursTimespanSelector, { HoursTimespan } from './HoursTimespanSelector';
import { IJobDetailed, JobLink, useJobActions, useJobReport } from '../Model/Job';
import { createDateColumn } from '../utils/DateUtils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        }
    }),
);

export default function CompletedJobs() {
    const classes = useStyles();

    const jobReport = useJobReport('state=completed');
    const jobActions = useJobActions();

    function onTimespanChanged(newTimespan: HoursTimespan) {
        jobReport.SetHoursTimespan(newTimespan);
    }

    function getJobData() {
        if (jobReport.data) {
            const sortedData = jobReport.data.sort((d1, d2) => {
                return (new Date(d2?.executionInformation?.endedDateTime!).getTime() - new Date(d1?.executionInformation?.endedDateTime!).getTime());
            });
            return sortedData;
        } else {
            return [];
        }
    }

    return (
        <div className={classes.root}>
            <MaterialTable<IJobDetailed>
                title={<HoursTimespanSelector default={jobReport.hoursTimeSpan} label="Completed Jobs" onValueChanged={onTimespanChanged} />}
                isLoading={jobReport.fetchCount === 0} // Avoid flicker on auto refresh and show loading only on first attempt
                columns={[
                    { title: "Name", field: "name", sorting: true, render: rowData => <JobLink job={rowData} />  },
                    { title: "Type", field: "type", sorting: true },
                    { title: "Outcome", field: "executionInformation.outcome" as any, sorting: true, lookup: { "success": 'Success', "failed": 'Failed', "cancelled": 'Cancelled' } },
                    { title: "Email", field: "email", sorting: true },
                    createDateColumn("Start", "executionInformation.startedDateTime"),
                    createDateColumn("End", "executionInformation.endedDateTime", "desc"),
                    { title: "Location", field: "dataCenter.location", sorting: true },
                ]}
                data={getJobData()}
                detailPanel={rowData => {
                    return (
                        <ReactJson src={rowData} displayDataTypes={false} displayObjectSize={false} />
                    )
                }}
                actions={[
                    {
                        icon: "refresh",
                        tooltip: "Refresh",
                        isFreeAction: true,
                        onClick: () => jobReport.refresh()
                    },
                    rowData => ({
                        tooltip: 'Download logs',
                        icon: 'get_app',
                        onClick: (event, rowData) => jobActions.downloadLogs(rowData)
                    })
                ]}
                options={{
                    pageSize: 10,
                    search: true,
                    sorting: true,
                    headerStyle: { backgroundColor: jobReport.error ? '#FF0000' : '#9BA5AE', color: '#000000' },
                    padding: 'dense'
                }}
            />
        </div>
    );

}


