import * as React from 'react';
import { Column } from "@material-table/core";
import moment from "moment";

export function dateStringToLocaleString(dateString: string) {

    var date = new Date(dateString);
    if (isNaN(date.getTime()))
        return "";

    return date.toLocaleString();
}

function renderDate(data: any, dateAccess: string) {
    var dateUTC = dateAccess.split('.').reduce(function (p, prop) { return p ? p[prop] : p }, data);
    return (<div> {dateStringToLocaleString(dateUTC)}</div>);
}

export function createDateColumn<RowData extends object>(label: string, dateAccess: string, defaultSort?: ('asc' | 'desc')): Column<RowData> {
    return (
        {
            title: label,
            field: dateAccess as any,
            type: "date",
            sorting: true,
            defaultSort: defaultSort,
            render: rowData => renderDate(rowData, dateAccess)
        });
}

export function toISOWithReducedPrecision(date: moment.Moment): string {
    return date.toISOString().slice(0, 19) + 'Z';
}

export function dateToISOWithReducedPrecision(date: Date): string {
    return date.toISOString().slice(0, 19) + 'Z';
}
