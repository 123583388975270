import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { useReactOidc } from '@axa-fr/react-oidc-context';

export interface ITokenState {
    token: string;
    tokenValue: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        textField: {
            width: '100vw',
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        button: {
            margin: theme.spacing(1)
        }
    }),
);

export default function CopyToken() {
    const classes = useStyles();
    const { oidcUser } = useReactOidc();
    const { enqueueSnackbar } = useSnackbar();

    const tokenValue = oidcUser.access_token
    const token = "Bearer " + tokenValue;

    return (
        <div>
            <form className={classes.container} noValidate autoComplete="off">
                <TextField
                    id="outlined-multiline-static"
                    label="Token"
                    multiline
                    rows="12"
                    value={token}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />
                <CopyToClipboard text={tokenValue}
                    onCopy={() => enqueueSnackbar("Token value copied!", { variant: "success" })}>
                    <Button variant="contained" color="secondary" className={classes.button}>
                        Copy Value
                    </Button>
                </CopyToClipboard>
                <CopyToClipboard text={token}
                    onCopy={() => enqueueSnackbar("Token copied!", { variant: "success" })}>
                    <Button variant="contained" color="secondary" className={classes.button}>
                        Copy Token
                    </Button>
                </CopyToClipboard>
            </form>
        </div>
    );
}
