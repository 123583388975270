export var JobLogMessageProperty;
(function (JobLogMessageProperty) {
    JobLogMessageProperty["Code"] = "code";
    JobLogMessageProperty["Source"] = "source";
    JobLogMessageProperty["Category"] = "category";
    JobLogMessageProperty["Title"] = "title";
    JobLogMessageProperty["Message"] = "message";
})(JobLogMessageProperty || (JobLogMessageProperty = {}));
export var JobLogType;
(function (JobLogType) {
    JobLogType["Error"] = "Error";
    JobLogType["Warning"] = "Warning";
})(JobLogType || (JobLogType = {}));
export var JobLogMessageSource;
(function (JobLogMessageSource) {
    JobLogMessageSource["Engine"] = "Engine";
    JobLogMessageSource["WorkerApp"] = "Worker app";
})(JobLogMessageSource || (JobLogMessageSource = {}));
export var JobLogMessageCategory;
(function (JobLogMessageCategory) {
    JobLogMessageCategory["User"] = "User";
    JobLogMessageCategory["Service"] = "Service";
})(JobLogMessageCategory || (JobLogMessageCategory = {}));
