import { useEffect } from 'react';
import CenteredProgress from './CenteredProgress';
import React, { createContext } from 'react'

const configFile = 'clientappconfig.json';

export interface IImsConfig {
    authorityUri: string,
}

export interface IConfig {
    version: string,
    ims: IImsConfig;
    azurePortalResourceGroup: string;
    seqUri: string;
}

export const ConfigContext = createContext<IConfig>({
    version: "",
    ims: {
        authorityUri: "",
    },
    azurePortalResourceGroup: "",
    seqUri: ""
});

export function useConfig(): IConfig {
    return React.useContext(ConfigContext);
}

export function ConfigLoader(props: any) {

    const [userInfo, setUserInfo] = React.useState<IConfig | undefined>(undefined);

    useEffect(() => {

        function fetchConfig() {
            console.log("Fetching config file : " + configFile);
            fetch(configFile)
                .then(res => res.json())
                .then(dataJson => {
                    setUserInfo(dataJson['clientApp']);
                    console.log("Config lodaded : " + JSON.stringify(dataJson))
                })
                .catch(error => {
                    console.error(`Failed to load ${configFile} : ${error}`);
                });
        }

        fetchConfig();
    }, []);

    if (userInfo === undefined)
        return <CenteredProgress />

    return (
        <ConfigContext.Provider value={{ ...userInfo as IConfig }}>
            {props.children}
        </ConfigContext.Provider>
    )
}
