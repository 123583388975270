import React from 'react';
import { node } from 'prop-types';
import Typography from '@mui/material/Typography';


export default function Title(props: any) {
    return (
        <Typography component="h2" variant="h6" color="primary" gutterBottom noWrap>
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
    children: node,
};
