import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginLeft: theme.spacing(2),
            minWidth: 120,
            backgroundColor: theme.palette.background.paper,
        },
        Select: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
        },
        form: {
            ...theme.typography.h6,
        },
    }),
);

export enum HoursTimespan {
    LastHour = 1,
    Last6Hours = 6,
    Last24Hours = 24,
    LastWeek = 7 * 24,
    Last2Weeks = 2 * 7 * 24,
    LastMonth = 31 * 24,
    LastQuarter = 3 * 31 * 24,
    Last2Quarters = 6 * 31 * 24,
}
interface IHoursTimespanSelectorProps {
    default: HoursTimespan,
    onValueChanged: any,
    label: string
}

export default function HoursTimespanSelector(props: IHoursTimespanSelectorProps) {

    const classes = useStyles();
    const [timespan, setTimespan] = React.useState<HoursTimespan>(props.default);
    const [open, setOpen] = React.useState(false);

    function handleChange(event: SelectChangeEvent<number>) {
        setTimespan(event.target.value as number);
        // must use value directly because 'timespan' state will be updated asynchrononsly
        props.onValueChanged(event.target.value as number);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    return (
        <form className={classes.form} autoComplete="off">
            {props.label}
            <FormControl className={classes.formControl} variant="standard" sx={{ m: 0, minWidth: 120 }}>
                <Select className={classes.Select}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={timespan}
                    onChange={handleChange}
                    inputProps={{
                        name: 'timespan'
                    }}
                >
                    <MenuItem value={HoursTimespan.LastHour}>Last hour</MenuItem>
                    <MenuItem value={HoursTimespan.Last6Hours}>Last 6 hours</MenuItem>
                    <MenuItem value={HoursTimespan.Last24Hours}>Last 24 hours</MenuItem>
                    <MenuItem value={HoursTimespan.LastWeek}>Last week</MenuItem>
                    <MenuItem value={HoursTimespan.Last2Weeks}>Last 2 weeks</MenuItem>
                    <MenuItem value={HoursTimespan.LastMonth}>Last month</MenuItem>
                    <MenuItem value={HoursTimespan.LastQuarter}>Last quarter</MenuItem>
                    <MenuItem value={HoursTimespan.Last2Quarters}>Last 2 quarters</MenuItem>
                </Select>
            </FormControl>
        </form>
    );
}
